<template>
<div class='page'>
 <div class="banner">
            <div class="w">
                <div class="info">
                    <div class="title">Surface Finishing Service</div>
                    <div class="he"></div>
                    <p class="title1">Your one-stop shop for manufacturing and surface finishing. Get your metal and plastic parts CNC machined and sandblasting, anodized, or powder coated in as fast as 5 days.</p>
                    
                  
                </div>
            </div> 
        </div>



  <myhead :hbg="hbg"></myhead>
  <bread :bbg="bbg"></bread> 
  <div class="capabilitiesbg">
    <div class="capabilities">
      <div class="H1">OUR SURFACE FINISHING CAPABILITIES</div>
      <div class="infotitle"> Use HLH as your one-stop solution for both manufacturing and surface finishing of your custom CNC machined parts,reducing the risk of production. Here is a list of standard surface finishes. For custom surface finishes such as laser cutting or hard anodizing, please contact: <span @click="jump('info@hlhcnc.com')" style="color:#DA251D">info@hlhcnc.com</span> </div>
    </div>
    
    
  </div>

  <modepo :modePolists="modePolists"></modepo>

  







</div>
</template>
<script>
import bread from '../../components/bread.vue'
import myhead from '../../components/myHead.vue'
import modepo from './modePo.vue'
export default {
data() {
return {
        hbg:{
        bg:'#ffffff'

    },
    bbg:{
 bg:'#ffffff',
 color:'#000000'
    },
      modePolists:[{
        H1:'POLISHING',
        info:'Polishing refers to the use of mechanical, chemical or electrochemical action to reduce the surface roughness of the product to obtain a bright and smooth surface. It is the modification of the surface of the product with polishing tools and abrasive particles or other polishing media.',
        listData:[{
            info:`<span style="color:#DA251D;font-weight: bold"> Applicable Materials: </span> PC, PMMA, Steel, Stainless, steel, brass, copper, Aluminum, etc `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Texture:</span>Smooth, glossy finish`,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Surface roughness:</span>Ra 0.2μm / Ra 32μm`,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Function: </span> Reduce product surface roughness, improve product appearance`,
        },],
        img:require('../../assets/img/modePomodel1.png'),

    },{
        H1:'ANODIZING',
        info:'Anodizing refers to the electrochemical anodizing of metals or alloys. Under the corresponding electrolyte and specific process conditions, aluminum and its alloys form an oxide film on the aluminum product (anode) due to the action of the applied current.',
        listData:[{
            info:`<span style="color:#DA251D;font-weight: bold">Applicable Materials: </span> Aluminum `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Colors: </span> Any RAL code or Pantone number `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Thickness: </span> 5-20μm `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Function: </span> Make the products not easy to be scratched, not easy to be corroded `,
        },],
        img:require('../../assets/img/modePomodel2.png'),

    },{
        H1:'SANDBLASTING',
        info:'Sandblasting is the process of cleaning and roughening the surface of the substrate by the impact of high-speed sand flow. Due to the impact and cutting effect of abrasives on the surface of the product, the surface of the product can obtain a certain degree of cleanliness and different roughness.',
        listData:[{
            info:`<span style="color:#DA251D;font-weight: bold"> Applicable Materials: </span> Aluminum, Steel, Stainless Steel, Brass, Copper `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Color: </span> Natural color of raw material `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Texture: </span> Uniform matte `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Function: </span> Improve the mechanical properties and fatigue resistance of the product, and increase the service life of the parts `,
        },],
        img:require('../../assets/img/modePomodel3.png'),

    },{
        H1:'PAINTING',
        info:`Painting refers to the surface coating processing of industrial products. Spray the specified color according to the customer's requirements.`,
        listData:[{
            info:`<span style="color:#DA251D;font-weight: bold"> Applicable Materials: </span> ABS，PC，PMMA，Aluminum，Stainless steel  `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Color: </span> Any RAL code or Pantone number `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Texture: </span> Uniform gloss or matte `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Function: </span> Improve the appearance of the product, and extend the service life of the product through an extra layer of protection. `,
        },],
        img:require('../../assets/img/modePomodel4.png'),

    },{
        H1:'POWDER COATING',
        info:'This is a coating process that uses corona discharge to make powder coatings adhere to the product. A wide variety of colors are available to create the desired aesthetic.',
        listData:[{
            info:`<span style="color:#DA251D;font-weight: bold"> Applicable Materials: </span> Aluminum, Steel, Stainless Steel `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Color: </span> Any RAL code or Pantone number `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Thickness: </span> 200-300μm `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Function: </span> Improve product appearance. Make products more wear-resistant and corrosion-resistant `,
        },],
        img:require('../../assets/img/modePomodel5.png'),

    },
    
    {
        H1:'CHROMATE CONVERSION COATING',
        info:'It refers to the treatment method of chemically reacting metal with chromate to form a stable chromate film on its surface.',
        listData:[{
            info:`<span style="color:#DA251D;font-weight: bold"> Applicable Materials: </span> Aluminum `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Color: </span> Clear, Yellow `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Applications: </span> Tools, hardware, electrical systems `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Function: </span> Increase the corrosion resistance of product while maintaining their conductive properties. `,
        },],
        img:require('../../assets/img/modePomodel6.png'),

    },{
        H1:'PASSIVATION',
        info:'Passivation refers to the process in which the metal is oxidized by a strong oxidant or electrochemical method to make the surface into an inactive state.',
        listData:[{
            info:`<span style="color:#DA251D;font-weight: bold"> Applicable Materials: </span> Steel , Stainless Steel `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Color: </span> Natural color of raw material `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Texture: </span> Smooth glossy finish `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Function: </span> Make products more corrosion-resistant. Increase the service life of the parts `,
        },],
        img:require('../../assets/img/modePomodel7.png'),

    },{
        H1:'ZINC PLATING',
        info:'This process is done by coating the part in a flux and dipping the part into molten zinc. The molten zinc forms a bond with the steel, creating a protective layer on the surface of the part.',
        listData:[{
            info:`<span style="color:#DA251D;font-weight: bold"> Applicable Materials: </span> Steel, Stainless Steel `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Texture: </span> Smooth finish `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Can be applied with: </span> Sandblasting, Passivation `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Function: </span> Prevent product surface oxidation or corrosion `,
        },],
        img:require('../../assets/img/modePomodel8.png'), 

    },{
        H1:'NICKEL PLATING',
        info:'Nickel plating is a process used to electroplate a thin layer of nickel onto a metal part.',
        listData:[{
            info:`<span style="color:#DA251D;font-weight: bold"> Applicable Materials: </span> Aluminum, Steel, Stainless Steel `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Texture: </span> Smooth finish `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Can be applied with: </span> Sandblasting, Painting`,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Function: </span> Make the product resistant to corrosion and abrasion, as well as for decorative purposes `,
        },],
        img:require('../../assets/img/modePomodel9.png'),

    },{
        H1:'ELECTROPOLISHING',
        info:'Electropolishing is an electrochemical process used to clean metallic surfaces and reduce micro-roughness of stainless steel parts. Useful to remove a thin (up to 10μm) layer of material.',
        listData:[{
            info:`<span style="color:#DA251D;font-weight: bold"> Applicable Materials: </span> Steel, Stainless Steel `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Texture: </span> Smooth, glossy finish `,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Color: </span> Natural color of raw material`,
        },{
            info:`<span style="color:#DA251D;font-weight: bold"> Function: </span> Improve surface finish `,
        },],
        img:require('../../assets/img/modePomodel10.png'),

    },
    
    ],
}
},
methods: {
  jump(url){
    window.open('https://'+url)

  }
},
mounted() {},
created() {},
props:{},
watch:{ },
components:{
  myhead,
  modepo,
  bread,
}
}
</script>
<style scoped lang="scss">
.page{
  
}

.infotitle{
 
  text-align: center;
  font-size: 18px;
font-family: Work Sans;
font-weight: 400;
color: #333333;
line-height: 36px;
}
.H1{
  text-align: center;
  margin-bottom: 60px;
  font-size: 34px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #DA251D;
}
.capabilitiesbg{
  width: 100%;
  margin-bottom:60px 
}
.capabilities{
  max-width: 990px;
  width: 80%;
  min-width: 330px;
  margin: auto
}

  .banner{
        background: url('~@/assets/img/surfaceFinishbanner.png') no-repeat;
        
  
        width: 100%;
        // height: 560px;
        transition: 0.25s;
    
        padding-top: 70px;
        .w{
            display: flex;
            justify-content: flex-end;
            margin-top: 87px;
        }
    
        .he{
          height: 5px;
          width: 60px;
          background: white;
          border-radius: 2px;
      
        }
        .title1{
            // font-size: 60px;
            margin-bottom: 2vw;
            transition: 0.25s;
            color: #ffffff;
          
      
        }
      
        .info {
      
    
            font-size: 24px;
            color: #333333;
            // margin-top: 30px;    
            .item{
                display: flex;
                align-items: center;
            
            }
            >p {
                // margin-bottom: 30px;
                img {
                    vertical-align: middle;
                    margin-right: 20px;
                    // width: 36px;
                    // height: 36px;
                    max-width: 36px;
                    min-width: 25px;
                 
                }
                span {
                    vertical-align: middle;
                }
            }
            >.title {
                // font-size: 48px;
                // margin-bottom: 0;
                color: white;
                font-weight: bold;
           transition: 0.25s;
            }
        }
    }
    
@media screen and (max-width: 760px) {   //最小  min-width="330px"
    .info{
  //  position: absolute;
    // right: 33vw;
    top: 141px;
    // width: 32%;
    }
    .w{
        width: 90%;
        min-width: 330px;
        justify-content: flex-start !important;
        margin: auto;
        min-width: 330px;
      margin-top: 126px !important;
    }
    .title{
        font-size: 23px;
        font-weight: bold;
          margin-bottom: 3vw;
    } 
    .title1{
           font-size: 16px;
        // font-weight: bold;
        width: 63vw;
          // line-height: 25px;
    }
    img{
        width: 26px
    }
    .itemT{
        font-size: 14px;
    }
    p{
        margin-bottom:10px ;
    }
    .banner{
        height: 536px;
          background-position: 15% 58% !important
    }
     .btn{
        font-size: 14px;
        margin-left: 48px;
    }
     .he{
      margin-bottom:20px;
    }

          .H1{
 font-size: 25px;
 margin-bottom:5vw 
    }
    .infotitle{
      font-size: 14px
      }
    
}

//平板
@media screen and (min-width: 760px) and (max-width: 1020px) {

    .w{
        width: 90%;
    }
    .title{
        font-size: 35px;
    margin-bottom: 12px;
    }
    .title1{
            font-size: 22px;
              line-height: 36px;
          width:50vw;
    }
    img{
        width: 30px;
    }
    .itemT{
        font-size: 22px;
    }
    p{
        margin-bottom:30px ;
    }
    .banner{
        height: 620px;
        background-position: center !important;
    }
    .btn{
        margin-left: 64px;
        font-size: 16px;
    }
    .info{
        margin-top: 30px;
        width: 48%;
    }
     .he{
      margin-bottom: 4vw;
    }
        .H1{
 font-size: 25px;
 margin-bottom:5vw 
    }
    .infotitle{
      font-size: 14px
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .H1{
 font-size: 30px
    }
    .infotitle{
      font-size: 16px
    }
    .w{
        width: 90%;
    }
    .title{
        font-size: 40px;
   margin-bottom: 12px;
    }
   .title1{
        font-size: 22px;
        width:45vw;
          line-height: 36px;
    }
    img{
        width: 36px;}
        .itemT{
            font-size: 24px;
        }  p{
            margin-bottom:30px ;
        }
        .banner{
            height: 620px;
            background-position: center !important;
        }
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
        .info{
            margin-top: 30px;
            width: 42%;
        }
         .he{
      margin-bottom: 34px;
    }
}



@media screen and (min-width: 1350px) {  //最大限制   width="1280px"
    .w{
        width: 90%;
    }
    .title{
        font-size: 50px;
     margin-bottom: 12px;
    }
    .title1{
           font-size: 22px;
        width: 650px;
          line-height: 36px;
    }
    .he{
      margin-bottom: 34px;
    }
    .itemT{
        font-size: 24px;
    }
    img{
        width: 36px;}
        p{
            margin-bottom:30px ;
        }
        .banner{
            height: 620px;
            background-position: center !important;
        }
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
        .info{
            margin-top: 30px;
            width: 42%;
        }
}
</style>