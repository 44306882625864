<template>
<div class='page'>
    <div class="content">
        <div class="model" v-for="(item,index) in modePolists" :key="index">
   <div class="H1">{{item.H1}}</div>
        <div class="modelContent">
             <div class="left">
                 <div class="leftTitle1">{{item.info}}</div>
               <div class="leftList">
                   <div class="item" v-for="(item1,index1) in item.listData" :key="index1">
                        <span v-html="item1.info" ></span>
                      
                   </div>
                     
               </div>
               </div>
              <div class="rig"><img :src="item.img" alt=""></div>
        </div>
        </div>
     
    </div>
</div>
</template>
<script>
export default {
data() {
return {

   
 
}
},
methods: {},
mounted() {},
created() {},
props:{modePolists:[]},
watch:{ },
components:{}
}
</script>
<style scoped>
.page{
    width: 100%;

}

.leftTitle1{
    font-size: 16px;
font-family: Work Sans;
font-weight: 400;
color: #333333;
line-height: 36px;
}
.leftList{
    margin: 20px 0
}
.left{
    width: 49%;
}

.item{
    font-size: 16px;
font-family: Work Sans;
 
line-height: 28px;
padding-bottom: 15px;
border-bottom:1px solid #cccccc ;
margin-bottom:15px 
}
.item:last-child{
    border-bottom:none 
}
    .rig{
    max-width: 500px;
    min-width: 330px;
}
.model{
    margin: 60px 0
}
.H1{
    font-size: 34px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #DA251D;
line-height: 75px;
margin-bottom:28px 
}
img{
    width: 100%;
}
.content{
    width: 1200px;
    margin: auto
}
.modelContent{
    display: flex;
    justify-content: space-between
}
 @media screen and (max-width: 760px) {
     .H1{
         text-align: center;
         font-size: 28px;
         line-height: 75px;
         margin-bottom: 5px;
     }
   .modelContent{
        display: flex;
        flex-direction: column-reverse;
        
    }
    .content{
        width: 90%;
        min-width: 330px
    }
    .rig{
         width: 100%;
        margin: auto
    }
    .left{
        width: 100%
    }
    .leftTitle1{
        margin: 4vw 0;
        font-size: 14px
    }
    .item{
        font-size: 14px
    }
    .model{
    margin: 22px 0
}
}
@media screen and (min-width: 760px) and (max-width: 1020px) {
.content{
        width: 90%;
    }
    .H1{
        font-size: 26px;
        line-height: 34px;
    }
    .leftTitle1{
        font-size: 14px;
        line-height: 27px;

    }
    .item{
        font-size: 13px;
        line-height: 21px;
    }
    .modelContent{
        /* display: flex;
        flex-direction: column-reverse; */
        
    }
    .model{
    margin: 60px 0
}

.left{
  width: 47%
}
.rig{
  width: 45%;
}
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
      .H1{
     font-size: 31px;
    line-height: 54px;
    }
    .content{
        width: 90%;
    }

.model{
    margin: 60px 0
}
.leftTitle1{
    font-size: 14px
}

.item{
    font-size: 14px;
    padding-bottom: 10px;
    margin-bottom:10px 
}
.left{
  width: 45%
}
.rig{
  width: 450px;
}
}
@media screen and (min-width: 1350px) {

    
}
</style>